<template>
  <div
    class="dashboard-documnets white radius-15 pb-4"
    :class="isMobile ? ' ' : ' pa-2'"
    @dragenter.prevent="dragToggle"
    @dragleave.prevent="dragToggle"
    @dragover.prevent
    @drop.prevent="dropToggle"
    v-if="profile.status == 4 || profile.status == 5"
  >
    <div
      class="px-6 radius-10-tl radius-10-tr py-4"
      :class="
        isMobile
          ? ' white mobile-head-shadow border-bottom-radius'
          : ' bluebgdash'
      "
    >
      <span
        class="register-primary--text font-16 font-700"
        :class="isMobile ? ' darkbord--text' : ''"
        >{{$t('dashboard.neededDocument')}}</span
      >
    </div>

    <div class="bluebgdash mt-5 px-5 pb-6 h-100">
      <span class="d-block px-1 py-4 font-18 font-700 darkbord--text"
        >{{$t('dashboard.pastDocuments')}}:</span
      >

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="2"
          v-for="(item, i) in items"
          :key="i"
          class="pa-1"
        >
          <div
            class="document px-3 py-4 flex-between-column"
            :class="isMobile ? 'd-flex align-center justify-space-between' : ''"
          >
            <span class="font-16 lightgreytext--text d-block">{{ item }}</span>
            <div>
              <span class="font-16 d-block mt-2 uppercase">{{
                item.split(".")[6]
              }}</span>
              <v-btn
                :class="isMobile ? '' : 'mt-4'"
                color="#96D4C9"
                dark
                elevation="0"
                :block="!isMobile"
                @click="showHandler(item)"
                outlined
                max-height="40px"
              >
                <span class="font-16">{{$t('dashboard.showDocument')}}</span>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="text-center mt-14">
        <span class="darktext--text font-16" v-if="!isMobile"
          >{{$t('forms.addOrDrag')}}</span
        >
        <div>
          <div class="file-plus d-inline-block">
            <img
              width="100%"
              class="d-block mx-auto cursor-pointer"
              :src="
                require(`@/assets/images/dashboard/icons/plus-circle${
                  isMobile ? '-fixed' : ''
                }.svg`)
              "
            />
            <v-file-input
              class="file"
              hide-input
              :hide-details="true"
              label=""
              placeholder=""
              dense
              multiple
              @change="submitHandler"
            ></v-file-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    items: [],
    dragActive: false,
  }),
  computed: {
    ...mapGetters(["profile"]),
  },
  methods: {
    showHandler(item) {
      window.open(item, "_blank");
    },
    dragToggle() {
      this.dragActive = !this.dragActive;
    },
    dropToggle(event) {
      this.dragActive = !this.dragActive;
      let files = [];
      if (event.dataTransfer.files.length > 0) {
        Array.from(event.dataTransfer.files).forEach((file) => {
          files.push(file);
        });
      }
      this.submitHandler(files);
    },
    async submitHandler(files) {
      let formData = new FormData();
      files.forEach((file) => {
        formData.append(`docs[]`, file);
      });
      let { data } = await this.$axios.post("file/upload", formData);

      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Updated Successfully",
          color: "success",
        });
        await this.getData();
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async getData() {
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "files";
      }
      let { data } = await this.$axios.get(url);
      if (data.success) {
        this.items = data.data.files;
      }
    },
  },
  async created() {
    if (this.profile) {
      if (this.profile.status == 4 || this.profile.status == 5) {
        await this.getData();
      } else {
        this.$router.replace("/dashboard/interviews");
      }
    }
  },
};
</script>

<style lang="scss">
.dashboard-documnets {
  min-height: calc(100vh - 180px);
  .document {
    background: white;
    border-radius: 6px;
    overflow-wrap: break-word;
    height: 240px;
    transition: 0.4s;
    &:hover {
      box-shadow: 0px 17px 31px 0px #87e5d54d;
    }
    .v-btn {
      transition: 0.3s;
      span {
        transition: 0.3s;
      }
      &:hover {
        background: $lightblue;
        span {
          color: white;
        }
      }
    }
  }
}
.file-plus {
  position: relative;
  overflow: hidden;
  .file {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    div,
    button {
      width: 100%;
      height: 100%;
    }
  }
  .v-icon:focus:after {
    opacity: 0;
  }

  @media (max-width: 960px) {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 25px;
    bottom: 25px;
  }
}
</style>
